// import packages
import React from "react";

//Import styles
import "../assets/styles/homepage/partners.scss";
import {
    Partners1Icon,
    Partners2Icon,
    Partners3Icon,
    Partners4Icon,
    Partners5Icon,
    Partners6Icon, Partners7Icon
} from "../assets/images";
import menuImg from "../assets/images/menu_img.png";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";

//Import Components

// Import utils

function Partners(props){
    const {staticTexts,partners} = props
    return <div className={'partners-block'}>
        <div className={'partners-title'}>
            {staticTexts?.about_page_partners_title}
        </div>
        <div className={'partners-wrapper'}>
            <div className={'top-block'}>
                {
                    partners.length && partners.slice(0,5).map((partner, index) => {
                        return <div className={'img-wrapper'}>
                            <img src={generateImageMediaUrl(partner?.mediaMain?.path)}/>
                        </div>
                    })
                }
            </div>
            <div className={'bottom-block'}>
                {
                    partners.length && partners.slice(5,10).map((partner, index) => {
                        return <div className={'img-wrapper'}>
                            <img src={generateImageMediaUrl(partner?.mediaMain?.path)}/>
                        </div>
                    })
                }
                {/*<Partners5Icon/>*/}
                {/*<Partners6Icon/>*/}
                {/*<Partners7Icon/>*/}
            </div>
        </div>
    </div>
}

export default Partners;
