// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

//import assets
import '../assets/styles/components/header.scss'
import {ReactComponent as Logo} from '../assets/images/Logo.svg';

// Import Components

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {Link} from "react-router-dom";
import {ChangeLanguage} from "../redux/actions";
import {BurgerIcon, CloseIcon} from "../assets/images";

function Header(props) {

    return <div className={`header-wrapper ${!props.mobileMenuIsOpen ? '' : 'openBg'}`}>
        <Link to={'/'} className={`${!props.mobileMenuIsOpen ? '' : 'none'}`}>
            <div className={'ask-logo'}>
                <Logo title={''}/>
            </div>
        </Link>
        <a className={`header_burger ${!props.mobileMenuIsOpen ? 'open-menu' : 'close-menu'}`}
           onClick={props.toggleMobileMenu}>
            {!props.mobileMenuIsOpen ? <BurgerIcon/> : <CloseIcon/>}
        </a>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'languages',
        'selectedLanguage',
        'services',
    ])
};
const mapDispatchToProps = {ChangeLanguage};
export default connect(mapStateToProps,mapDispatchToProps)(Header);
