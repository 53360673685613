// Import packages
import React, { Component } from "react";
import { Route, Switch, Router, Redirect, } from "react-router-dom";
import { store } from "../redux/store";

// Import utils
import { history } from "../configs/history";

// Import pages
import Layout from "../containers/Layout";
import Homepage from "../containers/Homepage";
import About from "../containers/About";
import Blog from "../containers/Blog";
import BlogDetails from "../containers/BlogDetails";
import Contact from "../containers/Contacts";
import Charities from "../containers/Charities";
import CharitiesDetails from "../containers/CharitiesDetails";
import Companies from "../containers/Companies";
import NotFound from "../containers/NotFound";

const locales = ['hy', 'en', 'ru']
export default class Routes extends Component {
    render () {
        return <Router history={history}>
            <Layout>
                <Route path={"/:locale?"} component={ChildrenRoutes}/>
            </Layout>
        </Router>
    }
}

class ChildrenRoutes extends Component {
    render () {
        const { location} = this.props
        const path = location.pathname
        const { selectedLanguage, languages } = store.getState().general || {}
        const selectedCode = selectedLanguage?.code || languages?.find(lg => lg.isMain)?.code || 'hy'
        const actualLocals = (languages && languages.length && languages.map(lg => lg.code)) || locales
        if (!path || path === '/') {
            history.push(`/${selectedCode}`)
            return null
        }
        let locale = path.split('/')[1]


        if (!actualLocals.includes(locale)) {
            const newPath = `/${selectedCode}${path}`
            history.push(newPath)
            return null
        }

        return <Switch>
            <Redirect exact from={`/${locale}`} to={`/${locale}/home`}/>
            <Route exact path={`/${locale}/home`} component={Homepage}/>
            <Route exact path={`/${locale}/about`} component={About}/>
            {/*<Route exact path={`/${locale}/team`} component={Team}/>*/}
            <Route exact path={`/${locale}/news`} component={Blog}/>
            <Route exact path={`/${locale}/contacts`} component={Contact}/>
            <Route exact path={`/${locale}/news/:slug`} component={BlogDetails}/>
            <Route exact path={`/${locale}/charities`} component={Charities}/>
            <Route exact path={`/${locale}/charities/:slug`} component={CharitiesDetails}/>
            <Route exact path={`/${locale}/companies/:slug`} component={Companies}/>
            <Route exact path={`/${locale}/404`} component={NotFound}/>
            <Route exact path={'*'} component={NotFound}/>
        </Switch>

    }
}
