import { GENERAL_CONSTS } from "../constants";
import { history } from "../../configs/history";

export const initialState = {
    languages: [],
    selectedLanguage: null,
    staticTexts: {},
    mainSlider: [],
    menuList: [],
    categories: [],
    blogs: {
        itemsList: [],
        hasMore: false,
        category: undefined,
    },
    lastNews: {
        itemsList: [],
        hasMore: false,
        category: undefined,
    },
    charities: {
        itemsList: [],
        hasMore: false,
        category: undefined,
    },
    lastCharities: {
        itemsList: [],
        hasMore: false,
        category: undefined,
    },
    singleBlog: null,
    singleCharities: null,
    singleCompanies: null,
    settings:null,
    blogPage: {},
    partners: [],
    services: [],
    servicesById: [],
    works: [],
    members: [],
    features: [],
    contacts: {},
    about: {},
    projects:{},
    customPages: {},
    countryCode: 'en',
    sliderIndex:null,
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case GENERAL_CONSTS.GET_LANGUAGES:
            const mainLanguage = action.payload.find(lg => lg.isMain);
            const selectedLanguageIsExist = state.selectedLanguage && state.selectedLanguage.id
                && action.payload.find(lg => lg.id === state.selectedLanguage.id);
            let newSelectedLanguage = selectedLanguageIsExist ? state.selectedLanguage :
                (mainLanguage ? mainLanguage : action.payload[0]);
            const pathCode = history.location?.pathname?.split('/')?.[1]
            if(pathCode && action.payload.find(lg => lg.code === pathCode)){
                newSelectedLanguage = action.payload.find(lg => lg.code === pathCode)
            }
            const newState = {
                ...state,
                languages: action.payload,
                selectedLanguage: newSelectedLanguage,
            };

            if (state.staticTexts && state.staticTexts[newSelectedLanguage.code]) {
                newState.translation = state.staticTexts[newSelectedLanguage.code]
            }
            return newState;
        case GENERAL_CONSTS.CHANGE_LANGUAGE:
            return {
                ...state,
                selectedLanguage: action.payload,
            };
        case GENERAL_CONSTS.GET_STATIC_TEXTS:
            return {
                ...state,
                staticTexts: action.payload[state.selectedLanguage?.code || 'en']
            };
        case GENERAL_CONSTS.GET_COUNTRY_CODE:
            return {
                ...state,
                // staticTexts: ;
            };
        case GENERAL_CONSTS.GET_MENU:
            return {
                ...state,
                menuList: action.payload
            };
        case GENERAL_CONSTS.GET_SLIDER:
            return {
                ...state,
                mainSlider: action.payload
            };
        case GENERAL_CONSTS.GET_SERVICES:
            return {
                ...state,
                services: action.payload
            };
        case GENERAL_CONSTS.GET_SERVICES_BY_ID:
            return {
                ...state,
                servicesById: action.payload
            };
        case GENERAL_CONSTS.GET_WORKS:
            return {
                ...state,
                works: action.payload
            };
        case GENERAL_CONSTS.GET_MEMBERS:
            return {
                ...state,
                members: action.payload
            };
        case GENERAL_CONSTS.GET_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            };
        case GENERAL_CONSTS.GET_BLOGS:
            return {
                ...state,
                blogs: {
                        count: action.payload.data.newsCount,
                        itemsList:action.payload.reset ? action.payload.data.news : [...state.data.news, ...action.payload.data.news],
                        hasMore: action.payload.hasMore
                }
            };
            case GENERAL_CONSTS.GET_LAST_NEWS:
            return {
                ...state,
                lastNews: {
                        count: action.payload.data.newsCount,
                        itemsList:action.payload.reset ? action.payload.data.news : [...state.data.news, ...action.payload.data.news],
                        hasMore: action.payload.hasMore
                }
            };
            case GENERAL_CONSTS.GET_CHARITIES:
            return {
                ...state,
                charities: {
                        count: action.payload.data.charitiesCount,
                        itemsList:action.payload.reset ? action.payload.data.charities : [...state.data.charities, ...action.payload.data.charities],
                        hasMore: action.payload.hasMore
                }
            };
            case GENERAL_CONSTS.GET_LAST_CHARITIES:
            return {
                ...state,
                lastCharities: {
                        count: action.payload.data.charitiesCount,
                        itemsList:action.payload.reset ? action.payload.data.charities : [...state.data.charities, ...action.payload.data.charities],
                        hasMore: action.payload.hasMore
                }
            };
        case GENERAL_CONSTS.GET_SINGLE_BLOG:
            return {
                ...state,
                singleBlog: action.payload,
            };
            case GENERAL_CONSTS.GET_SINGLE_CHARITIES:
            return {
                ...state,
                singleCharities: action.payload,
            };
            case GENERAL_CONSTS.GET_COMPANIES:
            return {
                ...state,
                singleCompanies: action.payload,
            };
        case GENERAL_CONSTS.GET_PARTNERS:
            return {
                ...state,
                partners: action.payload
            };
        case GENERAL_CONSTS.GET_FEATURES:
            return {
                ...state,
                features: action.payload
            };
        case GENERAL_CONSTS.GET_CONTACTS:
            return {
                ...state,
                contacts: action.payload
            };
        case GENERAL_CONSTS.GET_ABOUT:
            return {
                ...state,
                about: action.payload
            };
            case GENERAL_CONSTS.GET_PROJECT:
            return {
                ...state,
                projects: action.payload
            };
        case GENERAL_CONSTS.GET_CUSTOM_PAGES:
            return {
                ...state,
                customPages: action.payload,
            };
        case GENERAL_CONSTS.CLEAR_CUSTOM_PAGE:
            return {
                ...state,
                customPages: { ...initialState.customPages },
            };
        case GENERAL_CONSTS.GET_SETTINGS:
            return {
                ...state,
                settings:action.payload.charityIsActive,
            };
        case GENERAL_CONSTS.SLIDER_INDEX:
            return {
                ...state,
                sliderIndex:action.payload,
            };
        default:
            return state;
    }
}
