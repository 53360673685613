// import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//Import style
import '../assets/styles/homepage/homepage.scss'

//Import Components;


// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import SliderBox from "../components/homepage/SliderBox";
import {HomepageBgIcon, HomepageResponsBgIcon, InfoIcon, LinkArrIcon} from "../assets/images";
import {Link} from "react-router-dom";
import {getUrlWithLocal} from "../utils/getUrlWithLocal";


class Homepage extends Component {
    render() {
        const {staticTexts,mainSlider,sliderIndex} = this.props;

        return <div className="homepage-wrapper">
            <div className={'background'}>
                <HomepageBgIcon/>
            </div>
            <div className={'background-responsive'}>
                <HomepageResponsBgIcon/>
            </div>
            <div className={'blocks-wrapper'}>
                <div className={'left-wrapper'}>
                    <div className={'home-wrapper'}>
                        <div className={'home-title'}>
                            {staticTexts?.homepage_main_block_title}
                        </div>
                        <div className={'home-description'}>
                            {staticTexts?.homepage_main_block_description}
                        </div>
                        <Link to={getUrlWithLocal('/about')} className={'home-link'}>
                            {staticTexts?.see_more} <span> <LinkArrIcon/> </span>
                        </Link>
                    </div>
                    <div className={'moreInfo-wrapper'}>
                        <div className={'icon-wrapper'}>
                            <InfoIcon/>
                        </div>
                        <div className={'home-info'}>
                            {staticTexts?.homepage_main_info}
                        </div>
                    </div>
                </div>
                <div className={'right-wrapper'}>
                    <SliderBox mainSlider={mainSlider} staticTexts={staticTexts} index={sliderIndex}/>
                </div>
                <div className={'responsive-block'}>
                    <div className={'responsive-icon-wrapper'}>
                        <InfoIcon/>
                    </div>
                    <div className={'responsive-info'}>
                        {staticTexts?.homepage_main_info}
                    </div>
                </div>
            </div>
        </div>
    }
}


const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'requestLoading',
        'staticTexts',
        'mainSlider',
        'sliderIndex',
    ])
};

const mapDispatchToProps = {

};


export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
