// import packages
import React, {useEffect} from "react";
import {connect} from "react-redux";
import {Link, useLocation} from 'react-router-dom'

// Import assets
import '../assets/styles/components/footer.scss'

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {GetContacts,sendSubscribe} from "../redux/actions";
import {Collapse} from 'antd';
import {ReactComponent as Logo} from '../assets/images/Logo.svg';
import {FooterLogoIcon, PagRightIcon} from "../assets/images";
import {useState} from "react";
import isEmail from "validator/es/lib/isEmail";
import {InputGroup} from "./uiElements/inputGroup";
import {LoadingOutlined} from "@ant-design/icons";
import {getUrlWithLocal} from "../utils/getUrlWithLocal";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";

// const {Panel} = Collapse;

const {Panel} = Collapse;

function Footer(props) {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const location = useLocation();
    const {staticTexts,requestLoading,contacts} = props;

    useEffect(() => {
        setEmail("")
        setError(false)
    },[requestLoading])

    const getInputValues = (e) => {
        const {name, value} = e.target;
        setEmail(value);
        setError(false)
    };

    const sendSubscribe = () => {
        if (email && isEmail(email)) {
            props.sendSubscribe({email}).then(() => {
                setEmail(email)
            }, errorEmail => setError(!errorEmail))
        } else {
            setError(true)
        }
    }

    function downloadPresentation () {
        const file = contacts?.presentationFile;
        if (file) {
            fetch(generateImageMediaUrl(file?.path))
                .then(response => {
                    response.blob().then(blob => {
                        let a = document.createElement('a');
                        a.href = window.URL.createObjectURL(blob);
                        a.download = file?.title || 'presentation ';
                        a.click();
                    });
                });
        }
    }

    return <footer className='footer-wrapper'>
        <div className="desktop-footer-wrapper">
            <div className="top-part">
                {
                    contacts?.presentationFile && <div className={'footer-pdf-download'} onClick={downloadPresentation}>
                        {staticTexts?.footer_section_presentationFile_text || 'Ներբեռնեք ներկայացումը'}
                    </div>
                }
                {
                    location.pathname.includes('charities') || location.pathname.includes('news') ? '' : <div className={'subscribe-block'}>
                        <div className={'subscribe-info-block'}>
                            <div className="title">{staticTexts?.homepage_contact_title}</div>
                            <div className="description">{staticTexts?.homepage_contact_description}</div>
                        </div>
                        <div className={`input-wrapper ${error ? 'invalid' : ''}`}>
                            <InputGroup
                                inputType={'input'}
                                type={"text"}
                                value={email}
                                name={'email'}
                                placeholder={staticTexts?.homepage_contact_placeholder_email}
                                maxLength={100}
                                onChange={getInputValues}
                            />

                            <button className='sent-email'
                                    onClick={() => {
                                        if (!requestLoading && sendSubscribe) {
                                            sendSubscribe()
                                        }
                                    }}>
                                <PagRightIcon/>
                            </button>
                        </div>
                    </div>
                }
                <div className={'info-block'}>
                    <div className={'social-us'}>
                        <div className={'blocks'}>
                            <span>{staticTexts?.footer_section_services_title}</span>
                            <a href={`mailto:${contacts?.email}`} className={'info'}>
                                {contacts?.email}
                            </a>
                        </div>
                        <div className={'blocks'}>
                            <span>{staticTexts?.footer_section_call}</span>
                            <a href={`tel:${contacts?.phoneNumber}`} className={'info'}>
                                {contacts?.phoneNumber}
                            </a>
                        </div>
                    </div>
                    <div className={'follow-us'}>
                        <span className={'follow-us-title'}>{staticTexts?.footer_section_services}</span>
                        <div className={'links'}>
                            <span>
                                <a href={contacts?.facebook} target={'_blank'}>
                                    Facebook
                                </a>
                            </span>
                            <span>
                                <a href={contacts?.linkedin} target={'_blank'}>
                                    Linkedin
                                </a>
                            </span>
                            <span>
                                <a href={contacts?.instagram} target={'_blank'}>
                                    Instagram
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-part">
                <div className="copyright">{staticTexts?.footer_section_copyright_text}</div>
                <div className='developed-by'>
                    {staticTexts?.footer_section_developed_by_text}
                    <a href={"https://4steps.am/"} target={'_blank'} className={'footer-logo'}>
                       <FooterLogoIcon/>
                    </a>
                </div>
            </div>
        </div>
    </footer>

}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
        'selectedLanguage',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    GetContacts,
    sendSubscribe,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
