// import packages
import React, {useEffect, useRef} from "react";
import {connect} from "react-redux";
import {Pagination} from 'antd';

//Import assets
import '../assets/styles/containers/blog.scss'
import { history } from "../configs/history";


//Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {GetCharities, GetLastCharities} from "../redux/actions";
import {useState} from "react";
import {PagLeftIcon, PagRightIcon,} from "../assets/images";
import {PAGE_GET_COUNT, PAGE_NEWS_COUNT} from "../constants/constType";
import NewsCard from "../components/uiElements/cards/newsCard/NewsCard";
import NewsSlider from "../components/uiElements/newsSlider/NewsSlider";
import Footer from "../components/Footer";


function Charities(props){
    const {staticTexts,charities,lastCharities,settings} = props;
    const [currentPage, setCurrentPage] = useState(1);
    const isFetching = useRef(false)

    useEffect(()=>{
        if(!settings){
            history.push('/home')
        }
        props.GetCharities()
        props.GetLastCharities()
    },[])


    async function onPageChanged (news) {
        let count = news - 1
        isFetching.current = true;
        const configInfo = {
            reset: true,
            offset: (count * PAGE_NEWS_COUNT) + PAGE_GET_COUNT,
            limit: PAGE_NEWS_COUNT,
        }
            await props.GetCharities(configInfo)

        setCurrentPage(news);
    }

    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <a className={'prev_btn'}>
                <PagLeftIcon className={'arrow_icon'}/>
            </a>;
        }
        if (type === 'next') {
            return <a className={'next_btn'}>
                <PagRightIcon className={'arrow_icon'}/>
            </a>;
        }
        return originalElement;
    };

    return <div className={'blog-wrapper'}>
        <div className={'subscribe-wrapper'}>
            <div className={'title'}>
                {staticTexts?.charities_page_subscribe_title}
            </div>
            <div className={'description'}>
                {staticTexts?.charities_page_subscribe_description}
            </div>
        </div>
        <div className={'last-news-wrapper'}>
            <div className={'last-news-title'}>
                {staticTexts?.charities_page_last_news_title}
            </div>
            <NewsSlider blog={lastCharities?.itemsList} link={'charities'} staticTexts={staticTexts}/>
        </div>
        {
            charities?.itemsList?.length ? <div className={'news-wrapper'}>
                <div className={'news-wrapper-title'}>
                    {staticTexts?.charities_page_all_news_title}
                </div>
                {charities?.itemsList?.length && <div className={'news'}>
                    {charities?.itemsList?.map(item => {
                        return <NewsCard item={item} link={'charities'} staticTexts={staticTexts}/>
                    })}
                </div>}
            </div> : ''
        }
        <Pagination current={currentPage}
                    onChange={onPageChanged}
                    total={charities?.count - 10}
                    defaultPageSize={PAGE_NEWS_COUNT}
                    hideOnSinglePage={true}
                    itemRender={itemRender}
                    className={`pagination`}
        />

        <Footer/>
    </div>
}
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'selectedLanguage',
        'staticTexts',
        'lastCharities',
        'blogPage',
        'blogs',
        'charities',
        'settings'
    ])
};
const mapDispatchToProps = {
    GetCharities,
    GetLastCharities,
};

export default connect(mapStateToProps, mapDispatchToProps)(Charities);