// import packages
import React from "react";
import {connect} from "react-redux";

//Import assets
import '../assets/styles/containers/blog-details.scss'

//Import components

//Import utils
import {GetCharitiesBlog} from "../redux/actions";
import {getPropsFromState} from "../redux/mapStateToProps";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {
    BlogBgIcon,
    BlogResponsBgIcon, PagSliderRightIcon,
} from "../assets/images";
import Footer from "../components/Footer";
import CharitiesSlider from "../components/CharitiesSlider";
import {history} from "../configs/history";

function CharitiesDetails(props){
    const {singleCharities,staticTexts,settings} = props
    const params = useParams()

    useEffect(() => {
        if(!settings){
            history.push('/home')
        }
        props.GetCharitiesBlog(params.slug)
    },[params.slug])

    return <div className={'blog-details-wrapper'}>
        <div className={'blogBg'}>
            <BlogBgIcon/>
        </div>
        <div className={'responsiveBg'}>
            <BlogResponsBgIcon/>
        </div>
        <div className={'details-main-block'}>
            <div className={'left-block'}>
                <div className={'block-title'}>
                    {singleCharities?.title}
                </div>
                <div className={'block-description'}>
                    {singleCharities?.description}
                </div>
                {/*<div className={'block-date'}>*/}
                {/*    <span>{staticTexts?.see_more}</span> <PagSliderRightIcon/>*/}
                {/*</div>*/}
                <div className={'details-info-block'}>
                    <div className={'text'} dangerouslySetInnerHTML={{__html: singleCharities?.details}}/>
                </div>
            </div>
            <div className={'right-block'}>
                <div className={'img-block'}>
                    <img src={generateImageMediaUrl(singleCharities?.mediaMain?.path)}/>
                </div>
            </div>
        </div>
        {/*<div className={'details-info-block'}>*/}
        {/*    <div className={'text'} dangerouslySetInnerHTML={{__html: singleCharities?.details}}/>*/}
        {/*</div>*/}
        {singleCharities?.medias?.length ? <div className={'img-wrapper'}>
                <div className={'img'}>
                    <div className={'img-title'}>
                        {staticTexts?.charities_page_all_photo}
                    </div>
                    <div className={'img-block'}>
                       <CharitiesSlider sliderData={singleCharities?.medias}/>
                    </div>
                </div>
            </div> : ''
        }
        <Footer/>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'selectedLanguage',
        'singleCharities',
        'blogs',
        'staticTexts',
        'settings'
    ])
};

const mapDispatchToProps = {
    GetCharitiesBlog
};

export default connect(mapStateToProps, mapDispatchToProps)(CharitiesDetails);