// //----------------------------------Official-server---------------------------------------------------

const OFFICIAL_URLS = {
    BASE_URL: "https://app.puzzlegroup.am/front/api/v1",
    MEDIA_URL: "https://app.puzzlegroup.am/front/api/v1",
    HOST_MEDIA_URL: "https://app.puzzlegroup.am/front",
}
// const OFFICIAL_URLS = {
//     BASE_URL: "http://37.60.224.224:3200/api/v1",
//     MEDIA_URL: "http://37.60.224.224:3200/api/v1",
//     HOST_MEDIA_URL: "http://37.60.224.224:3200",
// }

//--------------------------------------- 4Steps-server --------------------------------------------

const TESTING_URLS = {
    BASE_URL: "http://173.249.20.192:3450/api/v1",
    MEDIA_URL: "http://173.249.20.192:3450/api/v1",
    HOST_MEDIA_URL: "http://173.249.20.192:3450",
}
const { BASE_URL, MEDIA_URL,HOST_MEDIA_URL } =
    OFFICIAL_URLS;
// TESTING_URLS;


export const HOST_MEDIA_URL_V1 = HOST_MEDIA_URL;


export const _urlLanguage = BASE_URL + "/languages";
export const _urlMedia = MEDIA_URL + "/media";
export const _urlStaticTexts = BASE_URL + "/static-texts";
export const _urlSlider = BASE_URL + "/slider";
export const _urlCategories = BASE_URL + "/categories";
export const _urlPartners = BASE_URL + "/partners";
export const _urlBlogs = BASE_URL + "/news";
export const _urlCharities = BASE_URL + "/charities";
export const _urlCompanies = BASE_URL + "/companies";
export const _urlCustomPages = BASE_URL + "/custom-pages";
export const _urlContacts = BASE_URL + "/contact";
export const _urlMenu = BASE_URL + "/menu";
export const _urlAbout = BASE_URL + "/about";
export const _urlSubscribers = BASE_URL + "/subscribers";
export const _urlCallRequest = BASE_URL + "/call-request";
export const _urlServices = BASE_URL + "/services";
export const _urlWorks = BASE_URL + "/works";
export const _urlMembers = BASE_URL + "/members";
export const _urlFeatures = BASE_URL + "/features";
export const _urlProjects = BASE_URL + "/projects";
export const _urlSettings = BASE_URL + "/settings";
// export const _urlCountryCode1 = "http://ip-api.com/json";
// export const _urlCountryCode2 = "https://geolocation-db.com/json/";

