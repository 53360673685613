// Import packages
import React, {memo} from "react";
import {connect} from 'react-redux';
import {Link} from "react-router-dom";

// Import assets
import '../assets/styles/components/right-menu.scss';

// Import components
import {getPropsFromState} from "../redux/mapStateToProps";
import {ChangeLanguage} from "../redux/actions";
import menuImg from "../assets/images/menu_img.webp";
import {LinkArrIcon} from "../assets/images";
import {history} from "../configs/history";
import {getUrlWithLocal} from "../utils/getUrlWithLocal";

// Import utils



const RightMenu = memo((props) => {
    const {className, staticTexts, toggleMobileMenu,languages,selectedLanguage,contacts,settings} = props;


    return <section className={`right-menu-wrapper ${className}`}>
        <div className={`right-menu-content ${className}`}>
            <nav className='mobile-nav'>
               <div className={'top-block'}>
                   <div className={'link-block'}>
                       <Link to={getUrlWithLocal('/')}>
                           <div className="nav-item">
                               <p>{staticTexts?.header_item_home}</p>
                               <span><LinkArrIcon/></span>
                           </div>
                       </Link>
                       <Link to={getUrlWithLocal('/about')}>
                           <div className="nav-item">
                               <p>{staticTexts?.header_item_about}</p>
                               <span><LinkArrIcon/></span>
                           </div>
                       </Link>
                       {
                           settings ? <Link to={getUrlWithLocal('/charities')}>
                               <div className="nav-item">
                                   <p>{staticTexts?.header_item_charities}</p>
                                   <span><LinkArrIcon/></span>
                               </div>
                           </Link> : ''
                       }
                       <Link to={getUrlWithLocal('/news')}>
                           <div className="nav-item">
                               <p>{staticTexts?.header_item_news}</p>
                               <span><LinkArrIcon/></span>
                           </div>
                       </Link>
                       <Link to={getUrlWithLocal('/contacts')}>
                           <div className="nav-item">
                               <p>{staticTexts?.header_item_contact}</p>
                               <span><LinkArrIcon/></span>
                           </div>
                       </Link>
                   </div>
                   <div className={'img-wrapper'}>
                       <div className={'img-block'}>
                           <img src={menuImg} alt=""/>
                       </div>
                   </div>
               </div>
                <div className={'bottom-block'}>
                    <div className="language-items">
                        {
                            languages?.length && languages?.map((item) => {
                                return <div key={item.id}
                                            onClick={() => {
                                                history.push(`/${item.code}${history.location.pathname.slice(3)}`)
                                                props.ChangeLanguage(item)
                                            }}
                                            className={'language-dropdown-item'}>
                                    <div className={`selected-language 
                                    ${selectedLanguage?.code === item.code ? 'active-language' : ''}`}>
                                        {item?.name}
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <div className={'page-wrapper'}>
                       <a href={contacts?.facebook} className={'links'} target={'_blank'}>
                           Facebook
                       </a>
                       <a href={contacts?.linkedin} className={'links'} target={'_blank'}>
                           Linkedin
                       </a>
                       <a href={contacts?.instagram} className={'links'} target={'_blank'}>
                           Instagram
                       </a>
                    </div>
                </div>
            </nav>
        </div>
        <div className={`mobile-menu-overlay`} onClick={toggleMobileMenu}/>
    </section>
})

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
        'languages',
        'selectedLanguage',
        'services',
        'settings'
    ])
};
const mapDispatchToProps = {
    ChangeLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(RightMenu);