// import packages
import React from "react";
import {getPropsFromState} from "../redux/mapStateToProps";
import {connect} from "react-redux";

// import assets
import '../assets/styles/containers/no-result.scss'
import emptyImg from "../assets/images/empty_view/not_found_empty.svg";

// import components
// import {LinkButton} from "../components/uiElements/buttons";

function NotFound(props) {
  
   const {staticTexts} = props

    return <div className="no-result-empty-view">
            <p>Page Not Found</p>
        </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
    ])
};
export default connect(mapStateToProps)(NotFound);
