// Import packages
import React, {useEffect, useState} from "react";

// Import styles
import "./progress-bar.scss";
import {getPropsFromState} from "../../redux/mapStateToProps";
import { getSliderIndex} from "../../redux/actions";
import {connect} from "react-redux";

 function ProgressBar(props) {
    const [animate, setAnimate] = useState(false);
    const {mainSlider,sliderCircleEnded,slideIndex} = props


    useEffect(() => {
        if (sliderCircleEnded) {
            setAnimate(false);
        } else {
            setAnimate(true);
        }
    }, [sliderCircleEnded]);

    return <div className="progress-wrapper">
        <div className={'index-wrapper'}>
            {
                mainSlider?.map((item,index) => {
                    return <span className={`start-index ${index === slideIndex ? 'active-index' : ''}`}>
                    {index < 9 ? 0 : ''}{index + 1}</span>
                })
            }
        </div>
        <div className={'progress-bar'}>
            <span className="stroke-wrapper">
            <span className={`stroke ${animate ? 'animate' : ''}`}/>
            </span>
        </div>
    </div>
}const mapStateToProps = (state) => {
    return getPropsFromState(state, [

    ])
};
const mapDispatchToProps = {
    getSliderIndex
};
export default connect(mapStateToProps,mapDispatchToProps)(ProgressBar);
