// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

//Import assets
import '../assets/styles/containers/about.scss'

//Import components
import Partners from "../components/Partners";

//Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";
import {GetAbout, GetPartners} from "../redux/actions";
import {AboutBgIcon, AboutImgBorderIcon, AboutResponsBgIcon} from "../assets/images";
import NewsSlider from "../components/uiElements/newsSlider/NewsSlider";
import Footer from "../components/Footer";


function About(props) {
    const {about,staticTexts,lastNews,partners} = props
    console.log(partners,'partners')

    useEffect(() => {
        props.GetAbout()
        props.GetPartners()
    },[])
    return <div className={'about-wrapper'}>
        <div className={'aboutBg'}>
            <AboutBgIcon/>
        </div>
        <div className={'responsiveBg'}>
            <AboutResponsBgIcon/>
        </div>
        <div className={'about-main-wrapper'}>
            <div className={'about-main'}>
                <div className={'about-main-title'}>
                    {about?.title}
                </div>
                <div className={'about-main-description'}>
                    {about?.description}
                </div>
            </div>
            <div className={'main-img'}>
                <div className={'img-block'}>
                    <img src={generateImageMediaUrl(about?.mediaMain?.path)}/>
                </div>
            </div>
        </div>
        <Partners staticTexts={staticTexts} partners={partners}/>

        {about?.blocks?.length && <div className={'blocks-wrapper'}>
            {about?.blocks.map(item => {
                return <div className={'blocks'} key={item}>
                    <div className={'img-wrapper'}>
                        <div className={'blocks-img'}>
                            <img src={generateImageMediaUrl(item?.mediaMain?.path)}/>
                        </div>
                        <div className={'border-block'}>
                            <AboutImgBorderIcon/>
                        </div>
                    </div>
                    <div className={'blocks-text'}>
                        <div className={'blocks-title'}>
                            {item?.title}
                        </div>
                        <div className={'blocks-description'} dangerouslySetInnerHTML={{__html: item?.description}}/>
                    </div>
                </div>
            })}
        </div>}
        <div className={'about-slider-wrapper'}>
            <div className={'text-wrapper'}>
                <div className={'slider-title'}>
                    {staticTexts?.about_page_slider_title}
                </div>
                <div className={'slider-description'}>
                    {staticTexts?.about_page_slider_description}
                </div>
            </div>
            <NewsSlider blog={lastNews?.itemsList} link={'news'} staticTexts={staticTexts}/>
        </div>
        <Footer/>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'about',
        'staticTexts',
        'partners',
        'lastNews',
    ])
};
const mapDispatchToProps = {
    GetAbout,
    GetPartners,
};

export default connect(mapStateToProps,mapDispatchToProps)(About);