// import packages
import React, {useState} from "react";
import {connect} from 'react-redux';
import isEmail from "validator/es/lib/isEmail";

//Import assets
import '../assets/styles/containers/contacts.scss';
import mainImg from "../assets/images/contact1.JPG"
import contactImg from "../assets/images/contact2.JPG"
import contactResponsiveImg from "../assets/images/contactResponciveImg.png"

//Import Components
import {InputGroup} from "../components/uiElements/inputGroup";

// Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import {SendEmail} from "../redux/actions";
import {LoadingOutlined} from "@ant-design/icons";
import {
    AboutImgBorderIcon,
    CompaniesBrIcon,
    ContactCallIcon,
    ContactLocationIcon,
    ContactMessageIcon, ContactsBrIcon
} from "../assets/images";
import Footer from "../components/Footer";

function Contact(props){
    const {staticTexts,contacts,requestLoading} = props
    const [fieldsData,setFieldsData] = useState({
        firstName:'',
        lastName:'',
        email:'',
        message:'',
    })
    const [error,setError] = useState({
        firstName:false,
        lastName:false,
        email:false,
        message:false,
    })

    function validate() {
        const error = {};
        let result = true;
        Object.keys(fieldsData).forEach(key => {
            if (!fieldsData[key] || (key === 'email' && !isEmail(fieldsData?.email))){
                error[key] = true;
                result = false;
            }

        })
        setError(error);
        return result;
    }
    function sendVisit() {
        if(validate()){
            props.SendEmail(fieldsData).then(() => {
            }).finally(() => {
                setFieldsData({
                    firstName:'',
                    lastName:'',
                    email:'',
                    message:'',
                })
            })
        }
    }

    function getInputValues(e) {
        const {name, value} = e.target
        setFieldsData({
            ...fieldsData,
            [name]: value
        })
        setError({
            ...error,
            [name]: false
        })
    }

    return <div className={'contacts-page-wrapper'}>
        <div className={'contact-main-block'}>
            <div className={'texts'}>
                <div className={'main-title'}>
                    {staticTexts?.contacts_page_main_title}
                </div>
                <div className={'main-description'}>
                    {staticTexts?.contacts_page_main_description}
                </div>
            </div>
            <div className={'main-img-block'}>
                <div className={'blocks-img'}>
                    <img src={mainImg}/>
                </div>
                <div className={'border-img'}>
                    <ContactsBrIcon/>
                </div>
            </div>
            <div className={'responsive-img'}>
                <div className={'responsive-block'}>
                    <img src={contactResponsiveImg} alt=""/>
                </div>
            </div>
        </div>
        <div className={'information-block'}>
            <div className={'header-block'}>
                <div className={'subTitle'}>
                    {staticTexts?.contacts_page_info_subTitle}
                </div>
                <div className={'title'}>
                    {staticTexts?.contacts_page_info_title}
                </div>
            </div>
            <div className={'info-wrapper'}>
                <div className={'contact-info'}>
                    <a href={`mailto:${contacts?.email}`} className={'info-icon'}>
                        <ContactMessageIcon/>
                    </a>
                    <div className={'info-title'}>
                        {staticTexts?.contacts_page_info_bloke1_title}
                    </div>
                    <a href={`mailto:${contacts?.email}`} className={'info-description'}>
                        {contacts?.email}
                    </a>
                </div>
                <div className={'contact-info'}>
                    <div className={'info-icon'}><ContactLocationIcon/></div>
                    <div className={'info-title'}>
                        {staticTexts?.contacts_page_info_bloke2_title}
                    </div>
                    <div className={'info-description'}>
                        {contacts?.address}
                    </div>
                </div>
                <div className={'contact-info'}>
                    <a href={`tel:${contacts?.phoneNumber}`} className={'info-icon'}>
                        <ContactCallIcon/>
                    </a>
                    <div className={'info-title'}>
                        {staticTexts?.contacts_page_info_bloke3_title}
                    </div>
                    <a href={`tel:${contacts?.phoneNumber}`} className={'info-description'}>
                        {contacts?.phoneNumber}
                    </a>
                </div>
            </div>
        </div>
        <div className={'contacts-block'}>
            <div className={'contacts-main-text'}>
                <div className={'main-title'}>
                    {contacts?.title}
                </div>
                <div className={'main-description'}>
                    {contacts?.description}
                </div>
            </div>
            <div className={'send-block'}>
                <div className={'contacts-main-block'}>
                    <div className={'contacts-inputs-wrapper'}>
                            <InputGroup
                                inputType={'input'}
                                type={"text"}
                                value={fieldsData.firstName}
                                error={error.firstName}
                                name={'firstName'}
                                placeholder={staticTexts?.contacts_page_placeholder_name}
                                maxLength={100}
                                onChange={getInputValues}
                            />
                            <InputGroup
                                inputType={'input'}
                                type={"text"}
                                value={fieldsData.lastName}
                                error={error.lastName}
                                name={'lastName'}
                                placeholder={staticTexts?.contacts_page_placeholder_lastName}
                                maxLength={100}
                                onChange={getInputValues}
                            />
                            <InputGroup
                                inputType={'input'}
                                type={"text"}
                                value={fieldsData.email}
                                error={error.email}
                                name={'email'}
                                placeholder={staticTexts?.contacts_page_placeholder_email}
                                maxLength={100}
                                onChange={getInputValues}
                            />
                            <InputGroup
                                inputType={'textarea'}
                                type={"text"}
                                value={fieldsData.message}
                                error={error.message}
                                name={'message'}
                                placeholder={staticTexts?.contacts_page_placeholder_message}
                                maxLength={100}
                                onChange={getInputValues}
                            />
                    </div>
                    <button className='sent-email'
                            onClick={() => {
                                if (!requestLoading && sendVisit) {
                                    sendVisit()
                                }
                            }}>
                        {staticTexts?.contacts_page_btn_send}
                        {
                            requestLoading ? <LoadingOutlined style={{fontSize: 14, color: "#fff"}}/>
                                : ''
                        }

                    </button>
                </div>
                <div className={'contact-img-wrapper'}>
                    <div className={'blocks-img'}>
                        <img src={contactImg}/>
                    </div>
                    <div className={'border-block'}>
                        <AboutImgBorderIcon/>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'contacts',
        'requestLoading',
    ])
};

const mapDispatchToProps = {
    SendEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
