import {getPropsFromState} from "../redux/mapStateToProps";
import {GetSingleCompanies} from "../redux/actions";
import {connect} from "react-redux";
import {useParams} from "react-router-dom";
import '../assets/styles/containers/companies.scss'
import {useEffect} from "react";
import {AboutImgBorderIcon, CompaniesBrIcon, PagSliderRightIcon} from "../assets/images";
import React from "react";
import {generateImageMediaUrl} from "../utils/generateMediaUrl";
import NewsSlider from "../components/uiElements/newsSlider/NewsSlider";
import Footer from "../components/Footer";
import moment from "moment";


function Companies(props){
    const {singleCompanies,staticTexts} = props
    console.log(singleCompanies,'singleCompanies')
    const params = useParams()

    useEffect(() => {
        props.GetSingleCompanies(params?.slug)
    },[])

    return <div className={'companies-wrapper'}>
        <div className={'companies-main-block'}>
            <div className={'left-block'}>
               <div className={'blocks-title'}>
                   {singleCompanies?.title}
               </div>
                <div className={'blocks-description'} dangerouslySetInnerHTML={{__html: singleCompanies?.description}} />
                <a href={singleCompanies?.link} className={'blocks-link'} target={'_blank'}>
                    <span>{staticTexts?.see_more}</span> <PagSliderRightIcon/>
                </a>
            </div>
            <div className={'right-block'}>
                <div className={'blocks-img'}>
                    <img src={generateImageMediaUrl(singleCompanies?.mediaMain?.path)}/>
                </div>
                <div className={'border-img'}>
                    <CompaniesBrIcon/>
                </div>
            </div>
        </div>
        <div className={'companies-news-blog'}>
            <div className={'news-title'}>
                {staticTexts?.companies_page_products_title}
            </div>
            <div className={'news-description'}>
                {staticTexts?.companies_page_products_description}
            </div>
            <NewsSlider blog={singleCompanies?.products} staticTexts={staticTexts} hrefs={singleCompanies?.productLink}/>
        </div>

        {singleCompanies?.news?.length &&
            <div className={'companies-blogs'}>
                <div className={'products-title'}>
                    {staticTexts?.companies_page_news_title}
                </div>
                <div className={'products-description'}>
                    {staticTexts?.companies_page_news_description}
                </div>
                {singleCompanies?.news.map(item => {
                    return <div className={'blocks'} key={item}>
                        <div className={'img-wrapper'}>
                            <div className={'blocks-img'}>
                                <img src={generateImageMediaUrl(item?.mediaMain?.path)}/>
                            </div>
                            <div className={'border-block'}>
                                <AboutImgBorderIcon/>
                            </div>
                        </div>
                        <div className={'blocks-text'}>
                            <div className={'blocks-date'}>
                                {moment(item?.date).format("DD.MM.YYYY")}
                            </div>
                            <div className={'blocks-title'}>
                                {item?.title}
                            </div>
                            <div className={'blocks-description'} dangerouslySetInnerHTML={{__html: item?.description}}/>
                            <a href={singleCompanies?.newsLink} className={'companies-block-link'} target={'_blank'}>
                                <span>{staticTexts?.see_more} <PagSliderRightIcon/></span>
                            </a>
                        </div>
                    </div>
                })}
            </div>
        }
        <div className={'components-info-block'}>
            <div className={'texts-block'}>
                <div className={'info-title'}>
                    {staticTexts?.companies_page_achievements_title}
                </div>
                <div className={'info-description'}>
                    {staticTexts?.companies_page_achievements_description}
                </div>
            </div>
            <div className={'info-wrapper'}>
                <div className={'block'}>
                    <div className={'number'}>
                        {singleCompanies?.year}
                    </div>
                    <div className={'texts'}>
                        {staticTexts?.companies_page_achievements_years}
                    </div>
                </div>
                <div className={'block'}>
                    <div className={'number'}>
                        {singleCompanies?.workers}
                    </div>
                    <div className={'texts'}>
                        {staticTexts?.companies_page_achievements_workers}
                    </div>
                </div>
                <div className={'block'}>
                    <div className={'number'}>
                        {singleCompanies?.contented}
                    </div>
                    <div className={'texts'}>
                        {staticTexts?.companies_page_achievements_contented}
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
}
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'singleCompanies',
    ])
};
const mapDispatchToProps = {
    GetSingleCompanies,
};

export default connect(mapStateToProps, mapDispatchToProps)(Companies);