// import packages
import React, {Component} from "react";
import Slider from "react-slick";

//Import styles
import "./newsSlider.scss";
import {SliderNextButton, SliderPrevButton} from "../buttons";
import NewsCard from "../cards/newsCard/NewsCard";

//Import Components

// Import utils

class NewsSlider extends Component {
    constructor() {
        super();
        this.state = {
            disabledPrev: true,
            disabledNext: false,
        };
    }

    componentDidMount() {
        let itemCount = this.getShowSlideCount();
        this.setState({
            disabledNext: this.props.blog?.itemsList?.length - 1 < itemCount,
        });

    }

    afterSlideChange = (next) => {
        let itemCount = this.getShowSlideCount();
        this.setState({
            disabledPrev: next === 0,
            disabledNext: next + itemCount === this.props.blog?.itemsList?.length,
        });
    };

    getShowSlideCount = () => {
        let width = window.innerWidth;
        if (width > 1200) return 5;
        else if (width > 900) return 4;
        else if (width > 600) return 3;
        else return 2;
    };

    render() {
        const {blog,link,staticTexts,hrefs} = this.props;
        const {disabledPrev, disabledNext} = this.state;

        const settings = {
            dots: false,
            arrows: false,
            infinite: blog?.length > 2 && true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode:  true,
            centerPadding: '60px 0 0',
            ref: blogs => (this.blog = blogs),
            beforeChange: (current, next) => this.afterSlideChange(next),
            responsive: [
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 2,
                        centerPadding: '60px 0 0',
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2,
                        infinite: true,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        infinite: true,
                        centerPadding: '40px 0 0',
                    }
                },
                {
                    breakpoint: 450,
                    settings: {
                        slidesToShow: 1,
                        infinite: true,
                        centerPadding: '0 0 0',
                    }
                },
            ]
        };

        return !!blog?.length && <div className="blog-slider-wrapper">
            <div className="blog-list-wrapper">
                {
                    <Slider {...settings} className={'news'}>
                        {blog?.map(item => {
                            return <NewsCard key={item}
                                             staticTexts={staticTexts}
                                             link={link}
                                             hrefs={hrefs}
                                             item={item}/>
                        })}
                    </Slider>
                }
                {blog?.length ?  <div className={'slider-arr'}>
                    {<SliderPrevButton disabledPrev={disabledPrev} cbPrev={() => this.blog.slickPrev()}/>}
                    {blog?.length > 3 && <SliderNextButton disabledNext={disabledNext} cbNext={() => this.blog.slickNext()}/>}
                </div> : ''}
            </div>
        </div>
    }
}

export default NewsSlider;
