// import packages
import React, {Component} from "react";
import Slider from "react-slick";
//import assets
import "../../assets/styles/homepage/slider.scss";

// Import Components
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";

// Import utils
import {history} from "../../configs/history";
import {DownloadIcon, LinkArrIcon} from "../../assets/images";
import {Link} from "react-router-dom";
import {getUrlWithLocal} from "../../utils/getUrlWithLocal";
import ProgressBar from "../progresBar/ProgressBar";

class SliderBox extends Component {
    constructor() {
        super();
        this.state = {
            slideIndex: 0,
            itemHeight: 0,
            nav1: null,
            nav2: null,
            sliderCircleEnded: false,
            firstImageLoaded: false,
            loadedImg: false
        };
        this.pagination = React.createRef();
        this.img = React.createRef();
        this.handleImageLoaded = this.handleImageLoaded.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
        window.addEventListener('resize', () => {
            this.setState({})
        })
    }

    // componentDidUpdate(prevProps) {
    //     const {index} = this.props
    //     if(index !== prevProps.index){
    //         this.setState({
    //             slideIndex:index + 1
    //         })
    //     }
    // }

    componentWillUnmount() {
        window.removeEventListener('resize', () => {
        })
    }

    downloadFile(pdf) {
        if (pdf) {
            fetch(generateImageMediaUrl(pdf?.path))
                .then(response => {
                    response.blob().then(blob => {
                        let a = document.createElement('a');
                        a.href = window.URL.createObjectURL(blob);
                        a.download = 'file';
                        a.click();
                    });
                });
        }
    }

    getSliderIndex = (index) => {
        this.setState({
            slideIndex: index,
        });
        this.slider.slickGoTo(index);
    };

    afterChangeSlideIndex = (slideIndex) => {
        this.setState({
            sliderCircleEnded: false,
        })
    };

    beforeChangeSlideIndex = (_, slideIndex) => {
        this.setState({
            slideIndex,
            sliderCircleEnded: true,
        })
    };

    handleImageLoaded() {
        if (!this.state.loadedImg) {
            this.setState({loadedImg: true});
        }
    }

    openUrl = () => {
        const {mainSlider} = this.props;
        const {slideIndex} = this.state;

        if (!!mainSlider[slideIndex]?.pageSlug) {
            history.push(`/pages/${mainSlider[slideIndex]?.pageSlug}`)
        } else if (!!mainSlider[slideIndex]?.url) {
            window.open(mainSlider[slideIndex].url);
        }
    };

    render() {
        const {slideIndex, sliderCircleEnded} = this.state;
        const {mainSlider, staticTexts} = this.props;

        console.log(slideIndex,'mainSlidermainSlider')

        const settingsSlider = {
            dots: false,
            arrows: false,
            infinite: true,
            speed: 1000,
            autoplay: true,
            pauseOnHover: false,
            swipeToSlide: false,
            swipe: true,
            className: 'imgSlider',
            afterChange: this.afterChangeSlideIndex,
            beforeChange: this.beforeChangeSlideIndex,
            asNavFor: this.state.nav2,
            ref: slider => (this.slider1 = slider),
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: window.innerWidth <= 1100 ? false : true,
            responsive: [
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: true,
                        centerPadding: '70px 0 0',
                    }
                },
            ]
        };
        const settingsContent = {
            dots: false,
            arrows: false,
            autoplay: false,
            pauseOnHover: false,
            swipeToSlide: false,
            swipe: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            className: 'contentSlider',
            asNavFor: this.state.nav2,
            ref: slider => (this.slider2 = slider),
        };
        const itemWidth = window.innerHeight - 450;
        return <div className="image-wrapper" onClick={this.openUrl}>
            <Slider {...settingsSlider} style={{height: itemWidth + 'px'}}>
                {mainSlider?.length && mainSlider?.map(item => {

                    return <div key={item.id} className={`img-wrapper`} ref={this.img}
                                style={{width: itemWidth}}>
                        <div className={'slider-item'}>
                            <img src={generateImageMediaUrl(item?.mediaMain?.path)}
                                 className="about-img"/>
                        </div>

                    </div>
                })}
            </Slider>
            <div className={'bottom-block'}>
                <Slider {...settingsContent}>
                    {mainSlider?.length && mainSlider?.map(item => {
                        return <div key={item.id} className={`slider-item`} ref={this.img}>
                            <div className="slider-content">
                                {item?.title ?
                                    <Link to={getUrlWithLocal(`/companies/${item?.company?.slug}`)} className="title">
                                        {item?.title} <span><LinkArrIcon/></span>
                                    </Link> : ''}
                                {item?.description ? <div className="description">{item?.description}</div> : ''}
                                {item?.pdfInfo ? <button className={`${item?.pdfInfo ? 'contact-btn' : ''}`}
                                                         onClick={() => this.downloadFile(item?.pdfInfo)}>
                                    <DownloadIcon/>
                                    <span>{staticTexts?.homepage_slider_download}</span>
                                </button> : ''
                                }
                            </div>
                        </div>
                    })}
                </Slider>
                <ProgressBar slideIndex={slideIndex}
                             sliderCircleEnded={sliderCircleEnded}
                             mainSlider={mainSlider}
                             sliderLenght={mainSlider?.length}/>
            </div>
        </div>

    }
}

export default SliderBox;
