// import packages
import React, {useEffect, useRef} from "react";
import {connect} from "react-redux";
import {Pagination} from 'antd';

//Import assets
import '../assets/styles/containers/blog.scss'


//Import utils
import {getPropsFromState} from "../redux/mapStateToProps";
import { GetBlogs,GetLastNews} from "../redux/actions";
import {useState} from "react";
import {PagLeftIcon, PagRightIcon,} from "../assets/images";
import {PAGE_GET_COUNT, PAGE_NEWS_COUNT} from "../constants/constType";
import NewsCard from "../components/uiElements/cards/newsCard/NewsCard";
import NewsSlider from "../components/uiElements/newsSlider/NewsSlider";
import Footer from "../components/Footer";


function Blog(props){
    const {staticTexts,blogs,lastNews} = props;
    const [currentPage, setCurrentPage] = useState(1);
    const isFetching = useRef(false)

    useEffect(()=>{
        props.GetBlogs()
        props.GetLastNews();
    },[])


    async function onPageChanged (news) {
        let count = news -1
        isFetching.current = true;
        const configInfo = {
            reset: true,
            offset: (count * PAGE_NEWS_COUNT) + PAGE_GET_COUNT,
            limit: PAGE_NEWS_COUNT,
        }
        await props.GetBlogs(configInfo)
        setCurrentPage(news);
    }


    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <a className={'prev_btn'}>
                <PagLeftIcon className={'arrow_icon'}/>
            </a>;
        }
        if (type === 'next') {
            return <a className={'next_btn'}>
                <PagRightIcon className={'arrow_icon'}/>
            </a>;
        }
        return originalElement;
    };

    return <div className={'blog-wrapper'}>
        <div className={'subscribe-wrapper'}>
            <div className={'title'}>
                {staticTexts?.blog_page_subscribe_title}
            </div>
            <div className={'description'}>
                {staticTexts?.blog_page_subscribe_description}
            </div>
        </div>
        <div className={'last-news-wrapper'}>
            <div className={'last-news-title'}>
                {staticTexts?.blog_page_last_news_title}
            </div>
            <NewsSlider blog={lastNews?.itemsList} link={'news'} staticTexts={staticTexts}/>
        </div>
        {
            blogs?.itemsList?.length ? <div className={'news-wrapper'}>
                <div className={'news-wrapper-title'}>
                    {staticTexts?.blog_page_all_news_title}
                </div>
               {blogs?.itemsList?.length &&  <div className={'news'}>
                   {blogs?.itemsList.map(item => {
                       return <NewsCard item={item} key={item.id} link={'news'} staticTexts={staticTexts} />
                   })}
               </div>}
            </div> : ''
        }
            <Pagination current={currentPage}
                        onChange={onPageChanged}
                        total={blogs?.count - 10}
                        defaultPageSize={PAGE_NEWS_COUNT}
                        hideOnSinglePage={true}
                        itemRender={itemRender}
                        className={`pagination`}
            />

        <Footer/>
    </div>
}
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'selectedLanguage',
        'staticTexts',
        'blogPage',
        'blogs',
        'lastNews',
    ])
};
const mapDispatchToProps = {
    GetBlogs,
    GetLastNews,
};

export default connect(mapStateToProps, mapDispatchToProps)(Blog);